import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";

const IndexPage = () => {
    return (
        <main>
            <title>Home Page</title>
            <Layout noDiaper>
                <section className="md:mt-0 pt-24 md:h-screen flex flex-col justify-center text-center md:text-left md:flex-row md:justify-between md:items-center lg:px-48 md:px-12 px-4 bg-secondary">
                    <div className="md:flex-1 md:mr-10">
                        <h1 className="text-7xl font-bold mb-7">
                            Learn Spanish <br />
                            <span className="text-6xl font-medium">
                                with
                            </span>{" "}
                            Memes
                        </h1>
                        <p className="mb-7 font-medium text-2xl">
                            ...because you remember interesting things better
                            than boring textbook examples. Make your vocabulary
                            stick and enrich your studying with stuff you find
                            entertaining.
                        </p>
                        <div>
                            <a href="https://apps.apple.com/us/app/momolingo/id1602532936">
                                <StaticImage
                                    alt="iOS app store badge"
                                    src="../images/app-store-badge.png"
                                    placeholder="none"
                                    height={46}
                                    quality={100}
                                />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.momolingo.app">
                                <StaticImage
                                    alt="android play store badge"
                                    src="../images/play-store-badge.png"
                                    placeholder="none"
                                    height={50}
                                    quality={100}
                                />
                            </a>
                        </div>
                    </div>
                    <div className="flex justify-center mt-8 md:-mt-8 md:flex-1">
                        <div className="relative">
                            <StaticImage
                                src="../images/wow1.svg"
                                alt=""
                                className="absolute -top-4 md:-top-24 -left-14"
                                height={80}
                                placeholder="none"
                                layout="fixed"
                            />
                        </div>
                        <StaticImage
                            src="../images/iphone-13-with-shadow-meme.png"
                            alt="Preview of the app in an iPhone"
                            placeholder="blurred"
                            // style={{
                            //     // maxHeight: "80vh",
                            //     width: "300px",
                            //     minHeight: "400px",
                            // }}
                            className="mt-4 md:-mt-16"
                            // objectFit="contain"
                            width={300}
                            quality={100}
                        />
                        <div className="relative">
                            <StaticImage
                                src="../images/wow2.svg"
                                alt=""
                                className="absolute -bottom-10 -right-6"
                                placeholder="none"
                                height={50}
                                layout="fixed"
                            />
                        </div>
                    </div>
                </section>
            </Layout>
        </main>
    );
};

export default IndexPage;
